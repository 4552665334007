<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'contract_registry' }"> {{$t('label_agreement_register')}}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>


        <b-card no-body>
            <b-card-body>
                <div class="text-uppercase mr-1 mb-1">
                    <strong> {{itemData.contract_subject}}</strong>
                </div>
                <b-button variant="outline-primary"
                          v-if="$can('edit', MODULE_PREFIX+'.tab_details')"
                          class="mr-1"
                          @click="editedItem = itemData;$bvModal.show(MODULE_PREFIX + '-modal')">
                    <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>

            </b-card-body>
        </b-card>

        <b-card no-body>
            <!--<b-card-header class="pb-50">-->
            <!--<h5 class="text-primary" v-if="itemData">{{itemData.contract_firstname + ' ' + itemData.contract_lastname}}</h5>-->
            <!--</b-card-header>-->
            <b-card-body>
                <b-tabs content-class="mt-1">
                    <b-tab

                            @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"

                    >
                        <b-row class="pt-1">

                            <b-col
                                    cols="12"
                                    md="6"
                                    class=" mb-1"
                            >

                                <b-table-simple class="table-details" responsive no-border-collapse>
                                    <b-tbody>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_category')}}</b-td>
                                            <b-td>
                                                {{itemData.category ? itemData.category.name : ''}}

                                                <!--todo add user_name-->

                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.id_contract_category">

                                                    <feather-icon id="popover-id_contract_category"
                                                                  icon="AlertCircleIcon" color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-id_contract_category"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_category')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.id_contract_category">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_date_agreement_conclusion')}}</b-td>
                                            <b-td>
                                                <b-button style="margin-bottom: -4px; margin-top: -6px;" size="sm" pill
                                                          v-if="$can('edit', 'agreement.add_annex')"
                                                          variant="outline-success"
                                                          class="btn  ml-1 mr-1"
                                                          @click="editedItem = itemData; isAnnex = true; $bvModal.show(MODULE_PREFIX + '-modal')">
                                                    {{$t('label_add_annex')}}
                                                </b-button>

                                                {{itemData.contract_date? formatDate(itemData.contract_date,
                                                'DD.MM.YYYY', 'YYYY-MM-DD') : ''}}

                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_date">

                                                    <feather-icon id="popover-contract_date" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_date"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_date_agreement_conclusion')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_date">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{formatDate(inf.from_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}</span>
                                                                => {{formatDate(inf.to_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>

                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_agreement_expiration_date')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_end_date? formatDate(itemData.contract_end_date,
                                                'DD.MM.YYYY', 'YYYY-MM-DD') : ''}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_end_date">

                                                    <feather-icon id="popover-contract_end_date" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_end_date"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_agreement_expiration_date')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_end_date">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{formatDate(inf.from_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}</span>
                                                                => {{formatDate(inf.to_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_valid_from')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_validity_date?
                                                formatDate(itemData.contract_validity_date, 'DD.MM.YYYY', 'YYYY-MM-DD')
                                                : ''}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_validity_date">

                                                    <feather-icon id="popover-contract_validity_date"
                                                                  icon="AlertCircleIcon" color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_validity_date"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_valid_from')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_validity_date">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{formatDate(inf.from_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}</span>
                                                                => {{formatDate(inf.to_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_valid_until')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_end_validity_date?
                                                formatDate(itemData.contract_end_validity_date, 'DD.MM.YYYY',
                                                'YYYY-MM-DD') : ''}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_end_validity_date">

                                                    <feather-icon id="popover-contract_end_validity_date"
                                                                  icon="AlertCircleIcon" color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_end_validity_date"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_valid_until')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_end_validity_date">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{formatDate(inf.from_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}</span>
                                                                => {{formatDate(inf.to_value,'DD.MM.YYYY', 'YYYY-MM-DD')}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_agreement_subject')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_subject}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_subject">

                                                    <feather-icon id="popover-contract_subject" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_subject"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_agreement_subject')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_subject">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_description')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_description}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_description">

                                                    <feather-icon id="popover-contract_description"
                                                                  icon="AlertCircleIcon" color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_description"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_description')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_description">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                            <b-col
                                    cols="12"
                                    md="6"
                                    class=" mb-1"
                            >
                                <b-table-simple class="table-details" responsive no-border-collapse>
                                    <b-tbody>

                                        <b-tr>
                                            <b-td width="40%">{{$t('label_supplier_executor_contractor')}}</b-td>
                                            <b-td>
                                                {{itemData.attendant_user}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.attendant_user">

                                                    <feather-icon id="popover-attendant_user" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-attendant_user"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_supplier_executor_contractor')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.attendant_user">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>

                                        <b-tr>
                                            <b-td width="40%">{{$t('label_country')}}</b-td>
                                            <b-td>
                                                {{itemData.country ?itemData.country.countryName : ''}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_id_country">

                                                    <feather-icon id="popover-contract_id_country"
                                                                  icon="AlertCircleIcon" color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_id_country"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_country')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_id_country">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_town')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_city}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_city">

                                                    <feather-icon id="popover-contract_city" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_city"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_town')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_city">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>

                                        <b-tr>
                                            <b-td width="40%">{{$t('label_origin')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_street}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_street">

                                                    <feather-icon id="popover-contract_street" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_street"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_origin')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_street">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_post_code')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_zip}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_zip">

                                                    <feather-icon id="popover-contract_zip" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_zip"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_post_code')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_zip">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_nip')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_nip}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_nip">

                                                    <feather-icon id="popover-contract_nip" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_nip"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_nip')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_nip">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_krs')}}</b-td>
                                            <b-td>
                                                {{itemData.contract_krs}}
                                                <template
                                                        v-if="itemData.changedFields && itemData.changedFields.contract_krs">

                                                    <feather-icon id="popover-contract_krs" icon="AlertCircleIcon"
                                                                  color="#ff9f43" size="20"
                                                                  class="annex-warning tooltip-html">i
                                                    </feather-icon>

                                                    <b-popover
                                                            class="bg-info"
                                                            target="popover-contract_krs"
                                                            triggers="hover"
                                                            placement="top"
                                                            variant="warning"
                                                    >
                                                        <template #title>
                                                            <span>{{$t('label_krs')}}</span>
                                                        </template>
                                                        <div class="tooltip-content">
                                                            <div v-for="inf in itemData.changedFields.contract_krs">
                                                <span class="badge badge-light-primary">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> <span>{{inf.from_value}}</span> =>
                                                                {{inf.to_value}}
                                                            </div>
                                                        </div>
                                                    </b-popover>
                                                </template>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>


                            </b-col>
                        </b-row>
                        <b-row v-if="itemData.annexes && itemData.annexes.length > 0" clss="mt-3">
                            <b-col cols="12">

                                <b-table-simple caption-top bordered small responsive no-border-collapse>
                                    <caption><b>{{$t('label_annexes')}}</b></caption>
                                    <b-thead head-variant="info">
                                        <b-tr>
                                            <b-th>{{$t('label_annex_adding_date')}}</b-th>
                                            <b-th>{{$t('label_annex_effective_date_from')}}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>

                                        <b-tr v-for="annex in itemData.annexes">
                                            <b-td>
                                                {{formatDate(annex.created_at, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss')}}
                                            </b-td>
                                            <b-td>
                                                {{formatDate(annex.apply_at, 'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss')}}
                                            </b-td>
                                        </b-tr>

                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>

                    </b-tab>
                    <b-tab
                            class="pb-2"
                            @click="curPageLabel = $t('label_documents')"
                            :title="$t('label_documents')"
                    >
                        <div class="table-container-wrap">
                            <b-table

                                    class="position-relative transited-table"
                                    :items="tableItems"
                                    responsive
                                    no-border-collapse
                                    :fields="columnsDefs"
                                    primary-key="id"
                                    :sort-by.sync="tableData.sortBy"
                                    no-local-sorting
                                    :sort-desc.sync="tableData.sortDesc"
                                    show-empty
                                    :tbody-transition-props="{ name: 'flip-list'}"
                                    :empty-text="$t('label_no_table_data')"

                            >

                                <template #head()="data">
                                    <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                                </template>
                                <template #cell(document)="data">
                                    <a class="preview-firstly" @click="previewFile($event, 'documents/download/', data.item)"  href="#">
                                        <feather-icon
                                                size="18"
                                                icon="FileTextIcon"
                                        />
                                    </a>
                                </template>
                                <template #cell(actions)="data">

                                    <b-dropdown
                                            dropleft
                                            boundary="window"
                                            variant="link"
                                            no-caret
                                            @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                            @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                            :right="$store.state.appConfig.isRTL"
                                    >

                                        <template #button-content>
                                            <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="16"
                                                    class="align-middle text-body"
                                            />
                                        </template>


                                        <b-dropdown-item
                                                :href="$base_url + 'contractregistry_documents/download/' + data.item.id + '?hash='+data.item.hash">
                                            <!--<a target="_blank" :href="$base_url + '_documents/download/' + data.item.id">-->
                                            <feather-icon icon="DownloadIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_download') }}</span>
                                            <!--</a>-->
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="deleteItem(data.item.id)">
                                            <feather-icon icon="TrashIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>

                            </b-table>
                        </div>
                        <div class="mx-2 mb-2">
                            <b-row>

                                <b-col
                                        cols="12"
                                        sm="6"
                                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                >
                                    <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col
                                        cols="12"
                                        sm="6"
                                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                >

                                    <b-pagination
                                            v-model="tableData.currentPage"
                                            :total-rows="tableTotal"
                                            :per-page="tableData.perPage"
                                            first-number
                                            last-number
                                            class="mb-0 mt-1 mt-sm-0"
                                            prev-class="prev-item"
                                            next-class="next-item"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                    icon="ChevronLeftIcon"
                                                    size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                    icon="ChevronRightIcon"
                                                    size="18"
                                            />
                                        </template>
                                    </b-pagination>

                                </b-col>

                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card-body>
        </b-card>

        <!--@item-added="refreshDataTable()"-->
        <process-item-modal
                :is-annex="isAnnex"
                @item-added="fetchItem()"
                @item-edited="fetchItem()"
                :edited-item="editedItem"
        ></process-item-modal>

    </div>
</template>

<script>

    import {
        BCard,
        BCardBody,
        BCardHeader,
        BTableSimple,
        BTbody,
        BTr,
        BTd,
        BThead,
        BTh,
        BButton,
        BBreadcrumbItem,
        BTabs,
        BTab,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BPopover

    } from 'bootstrap-vue'

    import {CONTRACT_REG_PREFIX as MODULE_PREFIX} from './moduleHelper'
    import processItemModal from './modal/processItemModal'

    export default {
        components: {
            BCard,
            BCardBody,
            BCardHeader,
            BTableSimple,
            BTbody,
            BTr,
            BTd,
            BThead,
            BTh,
            BButton,
            BBreadcrumbItem,
            BTabs,
            BTab,
            BTable,
            BDropdown,
            BDropdownItem,
            BPagination,
            BPopover,
            processItemModal
        },

        data() {
            return {
                MODULE_PREFIX,
                PREFIX: 'documents',
                itemData: {},
                curPageLabel: 'label_informations',
                columnsDefs: [
                    // {label: 'label_sl_no', key: 'id'},
                    {label: 'label_file_name', key: 'display_name'},
                    {label: 'label_document', key: 'document'},
                    {label: 'label_date_of_adding', key: 'created_at', formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix');
                        }
                    },
                    {label: 'label_action', key: 'actions'},
                ],
                tableTotal: 0,
                tableItems: [],
                notFilteredData: [],
                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                isAnnex: false,
                editedItem: false

            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },

        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },
        methods: {
            deleteItem(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', this.MODULE_PREFIX + '_' + this.PREFIX + '/' + id, {}, function (resp) {

                        this.refreshDataTable();
                    });
                });
            },
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', this.MODULE_PREFIX + '_' + this.PREFIX, {
                    params: {
                        contract_id: this.itemData.id_contract,

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,

                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });

            },
            fetchItem() {
                this.async('get', this.MODULE_PREFIX + '/' + this.$router.currentRoute.params.id, {}, function (res) {
                    this.itemData = res.data;
                    this.refreshDataTable();
                });
            }
        },
        created() {

            this.fetchItem();

        }

    }
</script>